<template>
  <div>
    <div class="pa-3">
      <h1 class="page-title">
        Профиль
      </h1>
    </div>
    <v-container fluid>
      <div>
        Имя:
      </div>
      <div>
        <v-btn dense tile elevation="0" color="green" class="white--text">
          изменить пароль
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProfileUser",
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
};
</script>
